/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import css from "../styles/Card.module.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const Card4 = ({ lang, currentCoeff, setCoefficients, secondDuration, setStep, coefficients }) => {
	const { t, i18n } = useTranslation();

	useEffect(() => {
		setCoefficients([currentCoeff, ...coefficients]);
		const timeoutId = setTimeout(() => {
			setStep(1);
		}, [secondDuration]);

		return () => clearInterval(timeoutId);
	}, []);

	return (
		<div className={css.card}>
			<div className={css.cardBody}>
				<div className={css.timerContainer}>
					<div
						className={css.tic}
						style={{ opacity: "0" }}
					/>

					<span
						className={classNames(
							i18n.resolvedLanguage === "en" ? "enMedium" : "hinMedium",
							css.timer,
						)}
						style={{ color: "#838383" }}
					>
						{t("roundOver")}
					</span>

					<div className={css.planeContainer}>
						<div className={classNames(css.plane3, css._stop)} />
					</div>
				</div>

				<div className={css.info}>
					<div className={classNames(css.enBold, css.coef, css._3)}>
						<span>x{currentCoeff}</span>
					</div>

					<p
						className={classNames(
							i18n.resolvedLanguage === "en" ? "enMedium" : "hinMedium",
							css.text,
						)}
						style={{ marginBottom: "0px" }}
					>
						{t("flewOff")}
					</p>
				</div>
			</div>
		</div>
	);
};

export default Card4;
