/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import css from "../styles/Card.module.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const Card3 = ({ coefficients, currentCoeff, timings, setStep, lang }) => {
	const { t, i18n } = useTranslation();
	const [time, setTime] = useState(18.05);
	const [anim, setAnim] = useState(1);

	useEffect(() => {
		let intervalId;
		const startTime = Date.now();
		intervalId = setInterval(() => {
      const elapsedTime = (Date.now() - startTime) / 1000;

			if (elapsedTime >= timings[0]) {
				setTime(0);
				setStep(4);
				clearInterval(intervalId);
			} else {
				setTime(timings[0] - elapsedTime);
			}
		}, 10);
		return () => clearInterval(intervalId);
	}, [timings]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (anim === 1) {
				setAnim(2);
			} else if (anim === 2) {
				setAnim(3);
			} else if (anim === 3) {
				setAnim(4);
			} else if (anim === 4) {
				setAnim(1);
			}
		}, 50);
		return () => clearTimeout(timeout);
	}, [anim]);

	const formatTime = () => {
		const seconds = Math.floor(time);
		const milliseconds = Math.floor((time - seconds) * 100);

		return (
			<>
				<span className={css.firstSecond}>{seconds < 10 && seconds !== 0 ? "0" : ""}</span>
				<span className={css.firstSecond}>{String(seconds).split("")[0]}</span>
				<span className={css.lastSecond}>{String(seconds).split("")[1]}</span>
				<span className={css.dot}>.</span>
				<span className={css.firstMillisecond}>{milliseconds < 10 ? "0" : ""}</span>
				<span className={css.firstMillisecond}>{String(milliseconds).split("")[0]}</span>
				<span className={css.lastMillisecond}>{String(milliseconds).split("")[1]}</span>
			</>
		);
	};

	return (
		<div className={css.card}>
			<div className={css.cardBody}>
				<div className={css.timerContainer}>
					<div className={css.tic} />
					<span className={classNames(css.enMedium, css.timer)}>{formatTime()}</span>
					<div className={css.planeContainer}>
						{/* <img className={css.plane} src="plane.gif" color="black" /> */}
						{/* <div className={classNames(css.plane1, anim && css._active)} />
						<div className={classNames(css.plane2, anim && css._active)} /> */}
						<div
							className={classNames(
								css.planeAlternative,
								anim === 1 && css._1,
								anim === 2 && css._2,
								anim === 3 && css._3,
								anim === 4 && css._4,
							)}
						/>
					</div>
				</div>

				<div className={css.info}>
					<div className={classNames(css.enBold, css.coef, css._2)}>
						<span>x{currentCoeff}</span>
					</div>

					<p
						className={classNames(
							i18n.resolvedLanguage === "en" ? "enMedium" : "hinMedium",
							css.text,
						)}
						style={{ marginBottom: "0px" }}
					>
						{t("currentCoef")}
					</p>
				</div>
			</div>
		</div>
	);
};

export default Card3;
