/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import css from "./styles/App.module.scss";
import axios from "axios";
import { useTranslation } from "react-i18next";
import CardContainer from "./components/CardContainer";
import Card1 from "./components/Card1";
import Card2 from "./components/Card2";
import Card3 from "./components/Card3";
import Card4 from "./components/Card4";

// const firstDuration = 7500;
const firstDuration = 5010;
const secondDuration = 9300;

const arrTimings = [
	{ start: 1.0, end: 1.5, time: 5 },
	{ start: 1.5, end: 2.0, time: 4 },
	{ start: 2.0, end: 3.0, time: 5 },
	{ start: 3.0, end: 4.0, time: 3 },
	{ start: 4.0, end: 5.0, time: 3 },
	{ start: 5.0, end: 6.0, time: 2 },
	{ start: 6.0, end: 7.0, time: 2 },
	{ start: 7.0, end: 8.0, time: 2 },
	{ start: 8.0, end: 9.0, time: 2 },
	{ start: 9.0, end: 10.0, time: 2 },
	{ start: 10.0, end: 11.0, time: 1 },
	{ start: 11.0, end: 12.0, time: 1 },
	{ start: 12.0, end: 13.0, time: 1 },
	{ start: 13.0, end: 14.0, time: 1 },
	{ start: 14.0, end: 15.0, time: 1 },
	{ start: 15.0, end: 1000, time: 0.03 },
];

function App() {
	const { t, i18n } = useTranslation();
	const [lang, setLang] = useState("en");
	const [coefficients, setCoefficients] = useState([]);
	const [coefficientsForTimings, setCoefficientsForTimings] = useState([]);
	const [currentCoeff, setCurrentCoeff] = useState(0);
	const [timings, setTimings] = useState([]);

	const [step, setStep] = useState(1);

	useEffect(() => {
		i18n.changeLanguage(lang);
	}, [lang]);

	useEffect(() => {
		(async () => {
			const res = await axios.get("https://aviator.raulnk.com/api/get");
			const newCoeffs = res?.data?.map(el => {
				return +el.value;
			});

			setCoefficientsForTimings(
				res?.data?.map(el => {
					return +el.value;
				}),
			);
			setCurrentCoeff(newCoeffs[0]);
			newCoeffs.splice(0, 1);
			newCoeffs.splice(5);

			setCoefficients(newCoeffs);
		})();
	}, []);

	useEffect(() => {
		const newTimings = [];

		coefficientsForTimings.forEach(coef => {
			const findIndex = arrTimings.findIndex(obj => coef >= obj.start && coef <= obj.end);

			let sumTime = 0;

			for (let i = 0; i < findIndex; i++) {
				if (i !== findIndex) {
					sumTime = sumTime + arrTimings[i].time;
				}
			}

			const x =
				(arrTimings[findIndex].end - arrTimings[findIndex].start) / arrTimings[findIndex].time;
			const y = coef - arrTimings[findIndex].start;

			sumTime = sumTime + y / x;

			newTimings.push(Math.round(sumTime * 100) / 100);
		});

		setTimings([...newTimings]);
	}, [coefficientsForTimings]);

	return (
		<div
			className={css.container}
			style={{ display: "flex", flexDirection: "column" }}
		>
			<CardContainer
				lang={lang}
				setLang={setLang}
				coefficients={coefficients}
			>
				{step === 1 && (
					<Card1
						setStep={setStep}
						lang={lang}
					/>
				)}
				{step === 2 && (
					<Card2
						setStep={setStep}
						lang={lang}
						currentCoeff={currentCoeff}
						firstDuration={firstDuration}
					/>
				)}
				{step === 3 && (
					<Card3
						setStep={setStep}
						lang={lang}
						currentCoeff={currentCoeff}
						timings={timings}
					/>
				)}
				{step === 4 && (
					<Card4
						setStep={setStep}
						secondDuration={secondDuration}
						lang={lang}
						currentCoeff={currentCoeff}
						setCoefficients={setCoefficients}
						coefficients={coefficients}
					/>
				)}
			</CardContainer>
		</div>
	);
}

export default App;
